import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'tk-agreement',
	standalone: true,
	templateUrl: './agreement.component.html',
	styleUrl: './agreement.component.sass',
	imports: [RouterLink],
})
export class AgreementComponent {
	@Input() buttonLabel = 'Отправить';
	@Input() theme: 'black' | 'white' = 'black';
}
